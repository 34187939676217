<template>
  <default-layout page-bg-class="bg-f0f1f4 md:bg-fafafc">
    <div
      class="my-auto w-full max-w-[660px] rounded-30 bg-fafafc p-40 shadow-xl shadow-black/5 md:bg-fafafc md:p-0 md:pt-16 md:shadow-none"
      :class="cardClass"
    >
      <slot />
    </div>
  </default-layout>
</template>

<script setup lang="ts">
import defaultLayout from "./default.vue"

const route = useRoute()
const cardClass = computed(() => route.meta.cardClass)
</script>
